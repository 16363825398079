import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-services',
  templateUrl: 'services.component.html',
  styleUrls: ['services.component.scss'],
  animations: [
    trigger('serviceAnimation', [
      transition('hidden => visible', [
        query('i', style({ opacity: 0, transform: 'translateX(-40px)' })),
        query('i', stagger('100ms', [
          animate('300ms ease-in', style({ opacity: 1, transform: 'translateX(0)'}))
        ]))
      ]),
    ])
  ]
})
export class ServicesComponent implements OnInit {
  @Input() currentServiceState;
  @ViewChild('services', { read: ElementRef }) elem;

  constructor() {}

  ngOnInit() {
    this.currentServiceState = 'hidden';
  }

}
