import { Component, HostListener, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  animations: [
    trigger('headerAnimation', [
      state('min', style({ opacity: '0.0', })),
      state('normal', style({ opacity: '1.0', })),
      transition('normal=>min', animate('300ms')),
      transition('min=>normal', animate('300ms'))
    ])
  ]
})
export class HeaderComponent implements OnInit {
  @Input() currentHeaderState;

  slogan = 'Our Mission Is Your Success';

  ngOnInit() {
    this.currentHeaderState = 'normal';
  }
}
