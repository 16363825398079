import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8'})
};
@Injectable()
export class DataService {

  constructor(private _http: HttpClient) {}

  sendEmail(data): any {
    return this._http.post('/api/email', data, httpOptions);
  }
}
