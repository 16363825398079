import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HeaderComponent, NavComponent, ServicesComponent} from './home';
import {PageScrollInstance, PageScrollService} from 'ngx-page-scroll';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  @ViewChild('scroller', { read: ElementRef }) g1;
  @ViewChild(HeaderComponent) header: HeaderComponent;
  @ViewChild(ServicesComponent) services: ServicesComponent;
  @ViewChild(NavComponent) nav: NavComponent;

  servicesAnimationInit = false;

  constructor(private _scrollService: PageScrollService,
              @Inject(DOCUMENT) private document: any) {

  }

  public goToServices(): void {
    const pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(this.document, '#services');
    this._scrollService.start(pageScrollInstance);
  }

  public goToMap(): void {
    const pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(this.document, '#map');
    this._scrollService.start(pageScrollInstance);
  }

  ngAfterViewInit() { window.addEventListener('scroll', this.onWindowScroll, true); }
  ngOnDestroy() { window.removeEventListener('scroll', this.onWindowScroll, true); }

  onWindowScroll = () => {
    const top = this.g1.nativeElement.scrollTop;

    const docViewBottom = top + window.outerHeight;

    const elemTop = this.services.elem.nativeElement.offsetTop;
    const elemBottom = elemTop + (this.services.elem.nativeElement.offsetHeight * 0.75);

    const isInView = ((elemBottom <= docViewBottom) && (elemTop >= top));

    if (isInView) {
      this.services.currentServiceState = 'visible';
    } else if (!this.servicesAnimationInit) {
      this.services.currentServiceState = 'hidden';
      this.servicesAnimationInit = true;
    }

    this.header.currentHeaderState = (top >= 70) ? 'min' : 'normal';
    this.nav.currentNavState = (top >= 70) ? 'min' : 'normal';
  }

}
