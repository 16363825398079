import {AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {trigger, style, transition, animate, state} from '@angular/animations';

@Component({
  selector: 'app-nav',
  templateUrl: 'nav.component.html',
  styleUrls: ['nav.component.scss'],
  animations: [
    trigger('navAnimation', [
      state('min', style({
        background: 'rgba(210, 180, 140, 1.0)'
      })),
      state('normal', style({
        paddingTop: '20px',
        paddingBottom: '20px',
        background: 'rgba(210, 180, 140, 0.8)'
      })),
      transition('normal=>min', animate('300ms')),
      transition('min=>normal', animate('300ms'))
    ])
  ]
})
export class NavComponent implements OnInit {
  @Input() currentNavState;

  isCollapsed = true;
  name = 'A & G Attorney Services';

  constructor() { }

  ngOnInit() {
    this.currentNavState = 'normal';
  }
}
