import {Component, OnInit} from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: 'map.component.html',
  styleUrls: ['map.component.scss'],
})
export class MapComponent implements OnInit {
  map;

  lat = 34.0146647;
  lon = -117.7440833;
  zoom = 10;

  latlng = new L.LatLng(this.lat, this.lon);

  constructor() {
  }

  ngOnInit() {
    this.map = L.map('map', { center: this.latlng, zoom: this.zoom  });
    this.map.scrollWheelZoom.disable();
    L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'mapbox.streets',
      accessToken: 'pk.eyJ1IjoiY2hyaXNlbmdlbHNtYSIsImEiOiI5OTZlZDUxYzk1MzBlZjRlYWMyYTVlZGY4ZWM1MzUwNSJ9.tLwdIXUxA9YVUoRvCURx4g'
    }).addTo(this.map);
    const circle = L.circleMarker(this.latlng, {
      color: 'red',
      fillColor: '#f03',
      fillOpacity: 0.5,
      radius: 10
    }).addTo(this.map);
    L.popup()
      .setLatLng(this.latlng)
      .setContent(this.makePopup())
      .openOn(this.map);
  }

  makePopup() {
    const p =
      '<div class="popup-container"><h3>A &amp; G Attorney Services</h3>' +
      '<div class="popup-body">' +
      '<p style="font-size: large;">' +
      '13089 Peyton Dr. Suite C-154<br />' +
      'Chino Hills, CA 91709' +
      '</p></div></div>';

    return p;
  }
}



