import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RecaptchaModule} from 'ng-recaptcha';
import {DataService} from './_services';
import {PhonePipe} from './_pipes';
import {RecaptchaFormsModule} from 'ng-recaptcha/forms';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';


import {AppComponent} from './app.component';
import {HeaderComponent, MapComponent, NavComponent, ServicesComponent} from './home';
import {AppRoutingModule} from './app-routing.module';
import {NgxPageScrollModule} from 'ngx-page-scroll';


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HeaderComponent,
    ServicesComponent,
    MapComponent,
    PhonePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    RecaptchaFormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    NgxPageScrollModule,
    RecaptchaModule.forRoot()
  ],
  providers: [
    DataService,
    PhonePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
